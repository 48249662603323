@use 'colors' as *;

button {
    padding: 0.4rem;
    margin-bottom: 0.5rem;
    border: 1px solid $grey;
    background-color: $grey;
    border-radius: 5px;
}

button:hover {
    background-color: $grey-dark;
    border-color: $grey-dark;
}

button:disabled {
    cursor: not-allowed;
}

.action {
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover {
        background-color: $green-dark;
        border-color: $green-dark;
    }

    &:disabled {
        background-color: $grey-dark;
        border-color: $grey-dark;
    }
}



.warning {
    background-color: $yellow;
    border-color: $yellow;
    color: $white;
    &:hover {
        background-color: $yellow-dark;
        border-color: $yellow-dark;
    }
    &:disabled {
        background-color: $grey-dark;
        border-color: $grey-dark;
    }
}



.cancel {
    background-color: $red;
    border-color: $red;
    color: $white;
    &:hover {
        background-color: $red-dark;
        border-color: $red-dark;
    }
    &:disabled {
        background-color: $grey-dark;
        border-color: $grey-dark;
    }
}

.neutral {
    background-color: $blue;
    border-color: $blue;
    color: $white;
    &:hover {
        background-color: $blue-dark;
        border-color: $blue-dark;
    }
    &:disabled {
        background-color: $grey-dark;
        border-color: $grey-dark;
    }
}


