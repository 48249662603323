@use 'colors' as *;

.nav-buttons {
    @media screen and (min-width: 1201px) {
        margin: 2rem;
    }
}

.nav-buttons > li {
    margin-bottom: 1rem;
    width: 100%;
    margin: 1rem 0px;

    @media screen and (min-width: 1201px) {
        width: 50%;
        margin: 1rem auto;
    }
}

.nav-buttons > li > a.btn {
    width: 100%;
}

.footer {
    color: #888888;
    font-size: 0.9rem;
    position: fixed;
    left: 50%;
    bottom: 0.5rem;
    transform: translate(-50%, -50%);
}

.quote-author {
    font-style: italic;
}


.video {
    width: 100%;
}

.avatar-container {
    float: left;
}

.avatar {
    width: 180px;
    height: 180px;
    border: 1px solid $grey-light;
    border-radius: 50%;
}

.about-list {
    list-style: none;
}
.emoji-bullet {
    font-size: 1.5rem;
    margin-right: 20px;
}

.gol-container {
    display: flex;
    @media screen and (max-device-width : 780px) {
        flex-direction: column;
    }
    @media screen and (min-device-width : 781px) {
        flex-direction: row;
    }
}

.life-grid {
    margin-bottom: 2rem;
}

.life-grid-row {
    clear: left;
}

.life-grid-cell {
    float: left;
    border: 1px solid $grey-light;
    background-color: $grey-vlight;
    border-radius: 50%;
    margin: 1px;
    
    @media screen and (max-device-width : 480px) {
        height: 12px;
        width: 12px;
    }
    @media screen and (min-device-width : 481px) {
        height: 20px;
        width: 20px;
    }

    
}

.alive {
    background-color: $alive;
}

.game-controls {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    label {
        font-size: 0.8rem;
    }

    button {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
        width: 20%;
    }

    .control-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 36px;
    }
}

.text-data {
    margin-bottom: 0.6rem;
}

.text-help {
    color: $grey-light;
    font-size: 0.75rem;
}

img.avatar-lg {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border: 1px solid $black;
    border-radius: 50%;
}

.project-list {
    list-style: none;
}

.project-list li {
    margin-bottom: 0.5rem;
}

.project-list li a {
    text-decoration: underline;
    color: $blue;

    &:hover {
        color: $blue-less;
    }
}

.cube-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
}

.cube-img-container {
    width: 50%;
    height: 50%;
    margin: 0 auto;
}

.cube-img {
    width: 200px;
}

.cube-algo-container {
    margin: 1rem;
    min-height: 3rem;
}

