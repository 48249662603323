header > nav {
  padding: 8px;
}
.site-logo {
  font-weight: 900;
  font-size: 1.1rem;
}

.nav-icon-list {
  list-style: none;
  padding: 0px;
  float: right;
  margin-right: 8px;
}

.nav-icon-list > li {
  display: inline-block;
  margin: 2px 3px;
}

img.nav-icon {
  height: 24px;
  width: 24px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
