@use 'colors' as *;
@use 'typography' as *;

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

body {
  font-family: monaco, monospace;
  font-size: $base-font-size;
  color: $base-font-color;
  background-color: $bg-color;
}

article.main-content {
  padding-top: 1rem;
  margin: 0 auto;
  width: 75%;
  @media screen and (max-device-width : 480px) {
    width: 95%;
  }
}


h1,h2,h3,h4,h5,h6 {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
}
h1 {
  font-size: 1.8rem;
}
h2 {
  font-size: 1.7rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.4rem;
}
h6 {
  font-size: 1.3rem;
}

a, .link {
  text-decoration: none;
  color: $white;
  &:hover {
    color: $blue;
    text-decoration: underline;
  }
}
