@use 'colors' as *;

.timeline {
    list-style: none;
    margin-top: 3rem;
}

.timeline-item {
    position: relative;
    margin-bottom: 2rem;
}

.timeline-item-date {
    color: $grey;
    font-size: 0.8rem;
}

.timeline-item-date-from::after {
    content: " - ";
}

.timeline-item-flex-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-device-width: 720px) {
        flex-direction: column;
    }
}
.timeline-item-short {
    flex-basis: 35%;
    cursor: pointer;
}

$animation-fadein-duration: 0.6s;

.timeline-item-long {
    flex-basis: 55%;
    max-height: 2rem;
    overflow-y: visible;
    -webkit-animation: fadein $animation-fadein-duration; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein $animation-fadein-duration; /* Firefox < 16 */
    animation: fadein $animation-fadein-duration;

    @media screen and (max-device-width: 720px) {
        max-height: unset;
        margin-top: 0.5rem;
    }
}

.timeline-item-long-technologies {
    color: $grey;
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.timeline-item-short-place {
    color: $grey;
    font-size: 0.8rem;
}

.hidden {
    display: none;
}

.selected-item {
    color: $blue;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
