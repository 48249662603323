* {
  box-sizing: border-box;
}

body {
  color: #fff;
  background-color: #2f2f2f;
  font-family: monaco, monospace;
  font-size: 12pt;
}

article.main-content {
  width: 75%;
  margin: 0 auto;
  padding-top: 1rem;
}

@media screen and (device-width <= 480px) {
  article.main-content {
    width: 95%;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1rem;
  margin-bottom: .4rem;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.4rem;
}

h6 {
  font-size: 1.3rem;
}

a, .link {
  color: #fff;
  text-decoration: none;
}

a:hover, .link:hover {
  color: #5c8fef;
  text-decoration: underline;
}

header > nav {
  padding: 8px;
}

.site-logo {
  font-size: 1.1rem;
  font-weight: 900;
}

.nav-icon-list {
  float: right;
  margin-right: 8px;
  padding: 0;
  list-style: none;
}

.nav-icon-list > li {
  margin: 2px 3px;
  display: inline-block;
}

img.nav-icon {
  opacity: .7;
  width: 24px;
  height: 24px;
}

img.nav-icon:hover {
  opacity: 1;
}

button {
  background-color: #bbb;
  border: 1px solid #bbb;
  border-radius: 5px;
  margin-bottom: .5rem;
  padding: .4rem;
}

button:hover {
  background-color: #888;
  border-color: #888;
}

button:disabled {
  cursor: not-allowed;
}

.action {
  color: #fff;
  background-color: #0dc81a;
  border-color: #0dc81a;
}

.action:hover {
  background-color: #0cb417;
  border-color: #0cb417;
}

.action:disabled {
  background-color: #888;
  border-color: #888;
}

.warning {
  color: #fff;
  background-color: #f4a234;
  border-color: #f4a234;
}

.warning:hover {
  background-color: #f29518;
  border-color: #f29518;
}

.warning:disabled {
  background-color: #888;
  border-color: #888;
}

.cancel {
  color: #fff;
  background-color: #f03737;
  border-color: #f03737;
}

.cancel:hover {
  background-color: #ee1c1c;
  border-color: #ee1c1c;
}

.cancel:disabled {
  background-color: #888;
  border-color: #888;
}

.neutral {
  color: #fff;
  background-color: #5c8fef;
  border-color: #5c8fef;
}

.neutral:hover {
  background-color: #3e7aec;
  border-color: #3e7aec;
}

.neutral:disabled {
  background-color: #888;
  border-color: #888;
}

@media screen and (width >= 1201px) {
  .nav-buttons {
    margin: 2rem;
  }
}

.nav-buttons > li {
  width: 100%;
  margin: 1rem 0;
}

@media screen and (width >= 1201px) {
  .nav-buttons > li {
    width: 50%;
    margin: 1rem auto;
  }
}

.nav-buttons > li > a.btn {
  width: 100%;
}

.footer {
  color: #888;
  font-size: .9rem;
  position: fixed;
  bottom: .5rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quote-author {
  font-style: italic;
}

.video {
  width: 100%;
}

.avatar-container {
  float: left;
}

.avatar {
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 180px;
  height: 180px;
}

.about-list {
  list-style: none;
}

.emoji-bullet {
  margin-right: 20px;
  font-size: 1.5rem;
}

.gol-container {
  display: flex;
}

@media screen and (device-width <= 780px) {
  .gol-container {
    flex-direction: column;
  }
}

@media screen and (device-width >= 781px) {
  .gol-container {
    flex-direction: row;
  }
}

.life-grid {
  margin-bottom: 2rem;
}

.life-grid-row {
  clear: left;
}

.life-grid-cell {
  float: left;
  background-color: #ededed;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 1px;
}

@media screen and (device-width <= 480px) {
  .life-grid-cell {
    width: 12px;
    height: 12px;
  }
}

@media screen and (device-width >= 481px) {
  .life-grid-cell {
    width: 20px;
    height: 20px;
  }
}

.alive {
  background-color: #39cf39;
}

.game-controls {
  flex-direction: column;
  margin-right: 1rem;
  display: flex;
}

.game-controls label {
  font-size: .8rem;
}

.game-controls button {
  width: 20%;
  margin-top: .5rem;
  margin-right: .5rem;
}

.game-controls .control-buttons {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 36px;
  display: flex;
}

.text-data {
  margin-bottom: .6rem;
}

.text-help {
  color: #ddd;
  font-size: .75rem;
}

img.avatar-lg {
  border: 1px solid #000;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.project-list {
  list-style: none;
}

.project-list li {
  margin-bottom: .5rem;
}

.project-list li a {
  color: #5c8fef;
  text-decoration: underline;
}

.project-list li a:hover {
  color: #6c9af1;
}

.cube-container {
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  display: flex;
}

.cube-img-container {
  width: 50%;
  height: 50%;
  margin: 0 auto;
}

.cube-img {
  width: 200px;
}

.cube-algo-container {
  min-height: 3rem;
  margin: 1rem;
}

.timeline {
  margin-top: 3rem;
  list-style: none;
}

.timeline-item {
  margin-bottom: 2rem;
  position: relative;
}

.timeline-item-date {
  color: #bbb;
  font-size: .8rem;
}

.timeline-item-date-from:after {
  content: " - ";
}

.timeline-item-flex-container {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (device-width <= 720px) {
  .timeline-item-flex-container {
    flex-direction: column;
  }
}

.timeline-item-short {
  cursor: pointer;
  flex-basis: 35%;
}

.timeline-item-long {
  flex-basis: 55%;
  max-height: 2rem;
  animation: .6s fadein;
  overflow-y: visible;
}

@media screen and (device-width <= 720px) {
  .timeline-item-long {
    max-height: unset;
    margin-top: .5rem;
  }
}

.timeline-item-long-technologies {
  color: #bbb;
  margin-top: .5rem;
  font-size: .8rem;
}

.timeline-item-short-place {
  color: #bbb;
  font-size: .8rem;
}

.hidden {
  display: none;
}

.selected-item {
  color: #5c8fef;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.btn-outline-primary {
  color: #5c8fef;
  border-color: #5c8fef;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #5c8fef;
  border-color: #5c8fef;
}

[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
  margin: 10px 0;
}

[type="range"]::-moz-focus-outer {
  border: 0;
}

[type="range"]:focus {
  outline: 0;
}

[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccedfc;
}

[type="range"]:focus::-ms-fill-lower {
  background: #c9ecfc;
}

[type="range"]:focus::-ms-fill-upper {
  background: #ccedfc;
}

[type="range"]::-webkit-slider-runnable-track {
  cursor: default;
  background: #c9ecfc;
  border: 2px solid #c9ecfc;
  border-radius: 5px;
  width: 100%;
  height: 8px;
  transition: all .2s;
  box-shadow: 1px 1px 1px #0003, 0 0 1px #0d0d0d33;
}

[type="range"]::-webkit-slider-thumb {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-appearance: none;
  background: #71a5be;
  border: 2px solid #3a667a;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  box-shadow: 4px 4px 4px #0003, 0 0 4px #0d0d0d33;
}

[type="range"]::-moz-range-track {
  cursor: default;
  background: #c9ecfc;
  border: 2px solid #c9ecfc;
  border-radius: 5px;
  width: 100%;
  height: 4px;
  transition: all .2s;
  box-shadow: 1px 1px 1px #0003, 0 0 1px #0d0d0d33;
}

[type="range"]::-moz-range-thumb {
  box-sizing: border-box;
  cursor: pointer;
  background: #71a5be;
  border: 2px solid #3a667a;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  box-shadow: 4px 4px 4px #0003, 0 0 4px #0d0d0d33;
}

[type="range"]::-ms-track {
  cursor: default;
  color: #0000;
  background: none;
  border-width: 10px 0;
  border-color: #0000;
  width: 100%;
  height: 8px;
  transition: all .2s;
}

[type="range"]::-ms-fill-lower {
  background: #b4e4fb;
  border: 2px solid #c9ecfc;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #0003, 0 0 1px #0d0d0d33;
}

[type="range"]::-ms-fill-upper {
  background: #c9ecfc;
  border: 2px solid #c9ecfc;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #0003, 0 0 1px #0d0d0d33;
}

[type="range"]::-ms-thumb {
  box-sizing: border-box;
  cursor: pointer;
  background: #71a5be;
  border: 2px solid #3a667a;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  box-shadow: 4px 4px 4px #0003, 0 0 4px #0d0d0d33;
}

[type="range"]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

[type="range"]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

[type="range"]:disabled::-ms-thumb {
  cursor: not-allowed;
}

[type="range"]:disabled::-webkit-slider-runnable-track {
  cursor: not-allowed;
}

[type="range"]:disabled::-ms-fill-lower {
  cursor: not-allowed;
}

[type="range"]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}
/*# sourceMappingURL=entry.c7dfece1.css.map */
